<template>
  <div style="margin: -10px -25px -10px -25px">
    <div class="box-container">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="userdepsid" style="width: 25%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="计划年份" prop="year" style="width: 25%">
              <a-select v-model="queryParams.year">
                <a-select-option v-for="(item, index) in yearOptions" :key="index" :value="item">{{item+'年'}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="月份" prop="month" style="width: 25%">
              <a-select v-model="queryParams.month">
                <a-select-option value="1">{{1+'月'}}</a-select-option>
                <a-select-option value="2">{{2+'月'}}</a-select-option>
                <a-select-option value="3">{{3+'月'}}</a-select-option>
                <a-select-option value="4">{{4+'月'}}</a-select-option>
                <a-select-option value="5">{{5+'月'}}</a-select-option>
                <a-select-option value="6">{{6+'月'}}</a-select-option>
                <a-select-option value="7">{{7+'月'}}</a-select-option>
                <a-select-option value="8">{{8+'月'}}</a-select-option>
                <a-select-option value="9">{{9+'月'}}</a-select-option>
                <a-select-option value="10">{{10+'月'}}</a-select-option>
                <a-select-option value="11">{{11+'月'}}</a-select-option>
                <a-select-option value="12">{{12+'月'}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <div class="query-btns" style="float: right;width: 20%">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
<!--              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>-->
            </div>
            <div v-show="showAdvanced" class="advanced-query">
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">{{pageTitle}}</h3>
          <div class="table-btns">
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
          </div>
        </div>
        <div style="width: 100%;">
          <div class="bar" id="energy_bar_left" style="height: 300px;width: 50%;float: left"></div>
          <div class="bar" id="energy_bar_right" style="height: 300px;width: 50%;float: right"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import {mapGetters, mapState} from "vuex";
import pagination from "@/mixins/pagination";
import {getCache, getItemFromArrayByKey} from "U/index";
import deptselect from "@/mixins/deptselect";
import { getOperatingStatementChartsData } from "A/businessmanagement";
import {getMonitorPointNameListByCondition} from "A/monitoring";
import * as echarts from "echarts";
export default {
  name: "energyExpend",
  mixins: [deptselect,pagination],
  data() {
    return {
      moment,
      pageTitle:'能耗实际用量',
      showAdvanced: false,
      queryParams:{
        monitorpointnum:'',
        monitorpointname:'',
        category:'能耗实际用量',
        finance_type:'支出',
        decision_type:'实际',
        year:'',
        month:'',
      },
      userdepidCascaderSelected: [],
      yearOptions: [],
      monitorpointList:[],

      xDataList1:[],
      xDataList2:[],
      xLabelList:[],
      xDataList3:[],

      xDataList4:[],
      xDataList5:[],
      xLabelList2:[],
      xDataList6:[],

      //操作按钮权限
      btnList:[],
      menu:{},
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['operationMenuTree']),
  },
  watch:{
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
  },
  created() {
    this.menu=getItemFromArrayByKey(this.operationMenuTree,"menuid",this.$route.params.menuid,"children")
    for(let i=0;i<this.menu.children.length;i++){
      this.btnList.push(this.menu.children[i].menuname)
    }
    this.getYearOptions();
    this.getMonitorPointNameList()
    this.getTableData()
    this.initDeptOptionsAll();
  },
  methods:{
    getMonitorPointNameList(){
      getMonitorPointNameListByCondition({userdepsid:this.userInfo.userdepid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.monitorpointList=res.item
        }
      })
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
      };
      getOperatingStatementChartsData(params).then(res => {
        this.tableLoading = false;
        if (res && res.returncode == '0') {
          this.resetEchartsData()
          this.xLabelList=res.label.map((item, index) => {
            if(item==='1'){
              return "公共用电"
            }else if(item==='2'){
              return "电梯用电"
            }else if(item==='3'){
              return "二次供水用水"
            }else if(item==='4'){
              return "车库照明用电"
            }else if(item==='5'){
              return "中央空调用电"
            }else if(item==='6'){
              return "服务中心办公用电"
            }else if(item==='7'){
              return "公共用水"
            }else if(item==='8'){
              return "园区用水"
            }else if(item==='9'){
              return "车库清洁用水"
            }else if(item==='10'){
              return "中央空调用水"
            }else if(item==='11'){
              return "燃气用量"
            }else if(item==='12'){
              return "商户用电"
            }else if(item==='13'){
              return "住户用电"
            }else if(item==='14'){
              return "住户用水"
            }else if(item==='15'){
              return "其他费用"
            }
          })
          this.xDataList1=res.plan
          this.xDataList2=res.actual
          this.xDataList3=res.rate

          this.xLabelList2=res.cumulativeLabel.map((item, index) => {
            if(item==='1'){
              return "公共用电"
            }else if(item==='2'){
              return "电梯用电"
            }else if(item==='3'){
              return "二次供水用水"
            }else if(item==='4'){
              return "车库照明用电"
            }else if(item==='5'){
              return "中央空调用电"
            }else if(item==='6'){
              return "服务中心办公用电"
            }else if(item==='7'){
              return "公共用水"
            }else if(item==='8'){
              return "园区用水"
            }else if(item==='9'){
              return "车库清洁用水"
            }else if(item==='10'){
              return "中央空调用水"
            }else if(item==='11'){
              return "燃气用量"
            }else if(item==='12'){
              return "商户用电"
            }else if(item==='13'){
              return "住户用电"
            }else if(item==='14'){
              return "住户用水"
            }else if(item==='15'){
              return "其他费用"
            }
          })
          this.xDataList4=res.cumulativePlan
          this.xDataList5=res.cumulativeActual
          this.xDataList6=res.cumulativeRate
          let that=this
          setTimeout(()=>{
            that.initCharts1()
            that.initCharts2()
          },500)
        }
      })
    },
    initCharts1(){
      let chartDom = document.getElementById('energy_bar_left');
      let myChart = echarts.init(chartDom);
      let option;
      option = {
        title:{
          text:"当月完成情况"
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#e20937'
            }
          }
        },
        toolbox: {
          feature: {
          },
          iconStyle:{
            normal:{
              color:'#000000',//设置颜色
            }
          }
        },
        legend: {},
        xAxis: [
          {
            type: 'category',
            data: this.xLabelList,
            axisPointer: {
              type: 'shadow'
            },
            axisLabel: {
              show: true,
              formatter: function (value) {
                let ret = "";//拼接加\n返回的类目项
                let maxLength = 2;//每项显示文字个数
                let valLength = value.length;//X轴类目项的文字个数
                let rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数
                if (rowN > 1)//如果类目项的文字大于5
                {
                  for (let i = 0; i < rowN; i++) {
                    let temp = "";//每次截取的字符串
                    let start = i * maxLength;//开始截取的位置
                    let end = start + maxLength;//结束截取的位置
                    //这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧
                    temp = value.substring(start, end) + "\n";
                    ret += temp; //凭借最终的字符串
                  }
                  return ret;
                } else {
                  return value;
                }
              },
            }
          }
        ],
        yAxis: [
          {
            show:true,
            type: 'value',
            axisLabel: {
              formatter: '{value} 元',
              textStyle:{
                fontSize: 10,
              }
            }
          },
        ],
        series: [
          {
            name: '月度预算数',
            type: 'bar',
            tooltip: {
              valueFormatter: function (value) {
                return value + ' 元';
              }
            },
            label: {
              show: true,
              position: 'top', // 在上方显示
              distance: 10, // 距离图形元素的距离，当 position 为字符描述值（如 'top'、'insideRight'）时候有效
              verticalAlign: 'middle', // 垂直对齐方式
              textStyle: { // 数值样式
                color: '#000', // 文本颜色
                fontSize: 12 // 字体大小
              }
            },
            data: this.xDataList1,
            itemStyle:{
              color:'#1482db',
            },
          },
          {
            name: this.queryParams.month+'月执行数',
            type: 'bar',
            tooltip: {
              valueFormatter: function (value) {
                return value + ' 元';
              }
            },
            label: {
              show: true,
              position: 'top', // 在上方显示
              distance: 10, // 距离图形元素的距离，当 position 为字符描述值（如 'top'、'insideRight'）时候有效
              verticalAlign: 'middle', // 垂直对齐方式
              textStyle: { // 数值样式
                color: '#000', // 文本颜色
                fontSize: 12 // 字体大小
              }
            },
            data: this.xDataList2,
            itemStyle:{
              color:'#820871',
            },
          },
        ]
      };
      option && myChart.setOption(option);
    },
    initCharts2(){
      let chartDom = document.getElementById('energy_bar_right');
      let myChart = echarts.init(chartDom);
      let option;
      option = {
        title:{
          text:"当期完成情况"
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#e20937'
            }
          }
        },
        toolbox: {
          feature: {
          },
          iconStyle:{
            normal:{
              color:'#000000',//设置颜色
            }
          }
        },
        legend: {},
        xAxis: [
          {
            type: 'category',
            data: this.xLabelList,
            axisPointer: {
              type: 'shadow'
            },
            axisLabel: {
              show: true,
              formatter: function (value) {
                let ret = "";//拼接加\n返回的类目项
                let maxLength = 2;//每项显示文字个数
                let valLength = value.length;//X轴类目项的文字个数
                let rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数
                if (rowN > 1)//如果类目项的文字大于5
                {
                  for (let i = 0; i < rowN; i++) {
                    let temp = "";//每次截取的字符串
                    let start = i * maxLength;//开始截取的位置
                    let end = start + maxLength;//结束截取的位置
                    //这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧
                    temp = value.substring(start, end) + "\n";
                    ret += temp; //凭借最终的字符串
                  }
                  return ret;
                } else {
                  return value;
                }
              },
            }
          }
        ],
        yAxis: [
          {
            show:true,
            type: 'value',
            axisLabel: {
              formatter: '{value} %',
              textStyle:{
                fontSize: 8,
              }
            }
          },
        ],
        series: [
          {
            name: '月度预算数',
            type: 'bar',
            tooltip: {
              valueFormatter: function (value) {
                return value + ' 元';
              }
            },
            label: {
              show: true,
              position: 'top', // 在上方显示
              distance: 10, // 距离图形元素的距离，当 position 为字符描述值（如 'top'、'insideRight'）时候有效
              verticalAlign: 'middle', // 垂直对齐方式
              textStyle: { // 数值样式
                color: '#000', // 文本颜色
                fontSize: 12 // 字体大小
              }
            },
            data: this.xDataList4,
            itemStyle:{
              color:'#1482db',
            },
          },
          {
            name: '月度执行数',
            type: 'bar',
            tooltip: {
              valueFormatter: function (value) {
                return value + ' 元';
              }
            },
            label: {
              show: true,
              position: 'top', // 在上方显示
              distance: 10, // 距离图形元素的距离，当 position 为字符描述值（如 'top'、'insideRight'）时候有效
              verticalAlign: 'middle', // 垂直对齐方式
              textStyle: { // 数值样式
                color: '#000', // 文本颜色
                fontSize: 12 // 字体大小
              }
            },
            data: this.xDataList5,
            itemStyle:{
              color:'#820871',
            },
          },
        ]
      };
      option && myChart.setOption(option);
    },
    resetQueryParams() {
      this.userdepidCascaderSelected=[]
      this.$refs.queryForm.resetFields();
      this.getYearOptions()
    },
    resetEchartsData(){
      this.xDataList1=[]
      this.xDataList2=[]
      this.xDataList3=[]

      this.xDataList4=[]
      this.xDataList5=[]
      this.xDataList6=[]
    },
    getYearOptions() {
      this.yearOptions = [];
      let currentYear = moment().year();
      let lastYear = moment().year()-1;
      for(let i=1; i>-1; i--) {
        this.yearOptions.push(currentYear+i+'');
      }
      this.yearOptions.push(lastYear+'')
      this.queryParams.year=currentYear+''
      this.queryParams.month=moment().month()+1+''
    },
  },
}
</script>
<style scoped>

</style>