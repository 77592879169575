<template>
  <page-container title="查看项目运营报表" :breadcrumb="breadcrumb">
    <div class="box-container">
      <div class="box-container-inner" style="margin: 10px -10px 10px -10px">
        <a-tabs size="small">
          <a-tab-pane key="tab1" tab="年度实际收入与支出">
            <income-and-expend></income-and-expend>
          </a-tab-pane>
          <a-tab-pane key="tab2" tab="能耗实际用量">
            <energy-expend></energy-expend>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
  </page-container>
</template>
<script>
import IncomeAndExpend from './incomeAndExpend/Index'
import EnergyExpend from './energyExpend/Index'
export default {
  name: 'yearOperationalPlan',
  components: {
    IncomeAndExpend,
    EnergyExpend,
  },
  data() {
    return {
      breadcrumb: [
        {
          name: '业务管理',
          path: ''
        },
        {
          name: '报告/报表管理',
          path: ''
        },
        {
          name: '项目运营报表',
          path: ''
        },
        {
          name: '查看项目运营报表',
          path: ''
        },
      ],
      activeKey: 'tab1'
    }
  },
  created() {},
  beforeDestroy() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.tab-container {
  padding: 0 24px;
  background-color: white;
}
.main-content {
  padding: 24px;
}
</style>